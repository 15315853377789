import { Component, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AtpBasePageComponent } from 'src/app/atp-core/atp-base-page-component';
import { AtpAddEditData, IAtpAddEditInfo } from 'src/app/atp-core/atp-core';
import { AtpDialogRef } from 'src/app/atp-core/components/atp-dialog/atp-dialog-container.component';
import { AtpDialogService, ATP_DIALOG_DATA } from 'src/app/atp-core/components/atp-dialog/atp-dialog.service';
import { AtpHttpErrorHandler } from 'src/app/atp-core/services/atp-http-errors.service';
import { ILotTemplates } from 'src/app/models';
import { HttpErrorsService } from 'src/app/services/http-errors.service';
import { HttpService } from 'src/app/services/http.service';
import { AddEditLotsComponent } from '../add-edit-lots/add-edit-lots.component';

@Component({
  selector: 'lot-templates',
  templateUrl: './lot-templates.component.html',
  host: {
    class: 'lot-templates lot-popup'
  }
})
export class LotTemplatesComponent extends AtpBasePageComponent {

  constructor(
    private _api: HttpService,
    private _httpErrors: HttpErrorsService,
    private _dialog: MatDialog,
    private _router: Router,
    @Inject(ATP_DIALOG_DATA) public data: ILotTemplates,
    private _atpDialog: AtpDialogService,
    private _dialogRef?: AtpDialogRef<AddEditLotsComponent>,
    private httpErrors?: HttpErrorsService
  ) {
    super();
  }

  ngOnInit(): void {

    this.isBlockedPage = true;

    this._api.getLotTemplatesList().subscribe(
      (result: ILotTemplates) => {

        this.data = result;

        this.isBlockedPage = false;
      },
      (err) => {
        this.httpErrors.process(err.status, () => {
        }, this, [new AtpHttpErrorHandler(400, err.error)]);
      }
    );
  }

  addLot(id: number) {
    this.isBlockedPage = true;
    this._api.addInfoLots('', id).subscribe(
      (result: IAtpAddEditInfo) => {
        this._atpDialog.open(AddEditLotsComponent, { data: new AtpAddEditData('Lots', null, result, null, { templateId: id }) }).afterClosed().subscribe(result => {
          if (result) {
            this._dialogRef.close(result);
          }
        });
        this.isBlockedPage = false;
      },
      err => {
        this._httpErrors.process(err.status, () => { this.addLot(id); }, this, [new AtpHttpErrorHandler(400, err.error)]);
      }
    );
  }
}
