<div *ngIf="isBlockedPage" class="atp-preloader-container"
  [class.atp-preloader-container_transparent]="!isPreloaderShow">
  <mat-spinner *ngIf="isPreloaderShow"></mat-spinner>
</div>

<div *ngIf="data" class="atp-dynamic-form__container">
  <div class="atp-dynamic-form__form">

    <atp-title class="atp-dynamic-form__title" text="Создание лота" notSetInHeader="false">
    </atp-title>

    <div class="atp-dynamic-form__content atp-dynamic-form__content_full-height atp-dynamic-form__content_detail-lot">

      <mat-tab-group class="atp-dynamic-form__tab-group atp-dynamic-form__tab-group_root">

        <mat-tab *ngFor="let type of data.types" [label]="type.value">

          <div *ngIf="data" class="lot-templates__items">
            <button mat-stroked-button *ngFor="let item of data.items[type.id]" class="lot-templates__item"
              (click)="addLot(item.id)">
              <h5 class="lot-templates__item-name">{{item.name}}</h5>
              <div class="lot-templates__item-description">{{item.description}}</div>
            </button>
          </div>

        </mat-tab>

      </mat-tab-group>

    </div>

    <div class="atp-dynamic-form__actions">
    </div>
  </div>

</div>
